import React, { Component } from 'react';
import "../App.css"
export const Construction = ({ }) => {

    return (
        <section className="under">
            <img src="../assets/20201208_161944.jpg" alt="under construction " />
            <h3> This website will be under maintenance from December 10th to January 11th. We apologize for the inconveniences</h3>
        </section>


    );
};
